import { defineStore } from 'pinia';

export const useCurrencyStore = defineStore({
  id: "currency",

  state: () => {
    return {
      _currencyUsd: null,
      _currencyCurrent: 'rub'
    }
  },

  actions: {
    getCurrencyUsd: async function () {
      const { $api } = useNuxtApp();
      const _res = await $api.agent.get('/currency/get-usd-rate')
        .then((res: any) => res?._data || null)
        .catch(() => null);
      this._currencyUsd = _res;
    },
    changeCurrencyCurrent: function (val: string) {},
  },
});
