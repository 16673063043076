import revive_payload_client_4sVQNw7RlN from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/grizzly-frontend-mirrors/release-20241011094925/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import initialPlugin_nVOZz4WWBl from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/sitis-nuxt-3/dist/runtime/plugins/initialPlugin.mjs";
import setCanonikal_eJ3HDgKmUc from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/sitis-nuxt-3/dist/runtime/server/common/setCanonikal.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_4F1kZh3YAB from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import unlocalePath_PD0JVOgyDY from "/var/www/grizzly-frontend-mirrors/release-20241011094925/plugins/unlocalePath.ts";
import initialLocale_4ceD83fttk from "/var/www/grizzly-frontend-mirrors/release-20241011094925/plugins/initialLocale.ts";
import toast_DCKv3PaVhZ from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/sitis-nuxt-3/dist/runtime/plugins/toast.mjs";
import numberFormat_TLCRT8l33c from "/var/www/grizzly-frontend-mirrors/release-20241011094925/plugins/numberFormat.ts";
import recaptcha_W4US4xmBV6 from "/var/www/grizzly-frontend-mirrors/release-20241011094925/plugins/recaptcha.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  initialPlugin_nVOZz4WWBl,
  setCanonikal_eJ3HDgKmUc,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_client_4F1kZh3YAB,
  unlocalePath_PD0JVOgyDY,
  initialLocale_4ceD83fttk,
  toast_DCKv3PaVhZ,
  numberFormat_TLCRT8l33c,
  recaptcha_W4US4xmBV6
]