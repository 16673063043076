import { defineStore } from 'pinia';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { useSmsUser } from '~/store/sms-user';

export interface IServiceItem {
  name: string;
  external_id: string;
  icon_id: number | undefined;
};
export interface IActionBuyNumber {
  serviceId: number | string;
  countryId: number | string;
};
interface IState {
  type: string;

  buyServices: any[];
  buyServicesPopular: any[];
  buyCountries: any[];
  buyCountriesPopular: any[];
  rentServices: any[];
  rentServicesPopular: any[];
  rentCountries: any[];

  buyServicesSelected: string | null;
  buyCountriesSelected: string | null;
  rentServicesSelected: string | null;
  rentCountriesSelected: string | null;

  buyServicesLoading: boolean;
  buyCountriesLoading: boolean;
  rentServicesLoading: boolean;
  rentCountriesLoading: boolean;

  loadingCards: string[];
};

export const useBuyRentNumberStore = defineStore({
  id: "buy-rent-number",

  state: (): IState => {
    return {
      type: "buy", // buy - Покупка номера | rent - Аренда номера

      buyServices: [],
      buyServicesPopular: [],
      buyCountries: [],
      buyCountriesPopular: [],
      buyServicesSelected: "",
      buyCountriesSelected: "",
      buyServicesLoading: true,
      buyCountriesLoading: true,

      rentServices: [],
      rentServicesPopular: [],
      rentCountries: [],
      rentServicesSelected: null,
      rentCountriesSelected: null,
      rentServicesLoading: true,
      rentCountriesLoading: true,

      loadingCards: [],
    }
  },

  getters: {
    serviceList: function (): any {
      if (this.type === "buy") {
        return this.buyServices
      }
      return this.rentServices
    },
    serviceSelected: function (): any {
      if (this.type === "buy") {
        return this.buyServicesSelected
      }
      return this.buyServicesSelected
    },
    serviceListPopular: function (): any {
      if (this.type === "buy") {
        return this.buyServicesPopular
      }
      return this.rentServicesPopular
    },

    countriesList: function (): any {
      if (this.type === "buy") {
        return this.buyCountries
      }
      return this.rentCountries
    },
    countriesListLoading: function (): any {
      if (this.type === "buy") {
        return this.buyCountriesLoading
      }
      return this.rentCountriesLoading
    },
  },

  actions: {
    changeType: async function (type: string) {
      this.type = type;

      if (type === "rent") {
        await this.getRentServices();
      }
    },

    getBuyServices: async function () {
      this.buyServicesLoading = true;
      this.buyCountriesLoading = true;

      const _response: any = await useNuxtApp().$api.agent.get('/service/all').then((res: any) => {
        return res?._data || []
      }).catch(() => {
        return []
      });

      this.buyServices = _response;
      this.buyServicesPopular = _response.filter((item: any) => Boolean(item.popular));
      this.buyServicesLoading = false;

      await this.getBuyCountries();
    },
    getBuyCountries: async function () {
      this.buyCountriesLoading = true;

      const userId = useSitisAuth().currentUser.value?.id || "guest";
      const apiUrl = `/country/get-prices/${this.buyServicesSelected}?page=1&user=${userId}`;
      const _response = await useNuxtApp().$api.agent.get(apiUrl).then((res: any) => {
        return res?._data || []
      }).catch(() => {
        return []
      });

      this.buyCountries = _response;
      this.buyCountriesLoading = false;
    },
    changeBuyServicesSelected: async function (serviceId: string) {
      const buyServicesSelected = useCookie("selectedServiceId", {
        path: "/",
        maxAge: (60 * 60 * 24 * 30 * 12)
      });
      buyServicesSelected.value = String(serviceId);

      this.buyServicesSelected = String(serviceId);
      await this.getBuyCountries();
    },
    changeBuyServicesSelectedNotApi: async function (serviceId: string) {
      const buyServicesSelected = useCookie("selectedServiceId", {
        path: "/",
        maxAge: (60 * 60 * 24 * 30 * 12)
      });
      buyServicesSelected.value = String(serviceId);
      this.buyServicesSelected = String(serviceId);
    },
    changeBuyCountriesSelected: async function (countryId: string) {},

    getRentServices: async function () {
      const _response = await useNuxtApp().$api.agent.get('/rent/get-services').then((res: any) => {
        return res?._data || []
      }).catch(() => {
        return []
      });

      this.rentServices = _response;
      this.rentServicesPopular = _response.filter((t: any) => Boolean(t.popular));
      this.rentServicesLoading = false;

      await this.getRentCountries();
    },
    getRentCountries: async function () {
      this.rentCountriesLoading = true;

      const userId = useSitisAuth().currentUser.value?.id || "guest";
      const apiUrl = `/rent/get-prices?service_id=${this.serviceSelected}&page=1&user=${userId}`;
      const _response = await useNuxtApp().$api.agent.get(apiUrl).then((res: any) => {
        return res?._data || []
      }).catch(() => {
        return []
      });

      this.rentCountries = _response;
      this.rentCountriesLoading = false;
    },
    changeRentServicesSelected: async function (serviceId: string) {},
    changeRentCountriesSelected: async function (countryId: string) {},

    initAllState: async function () {
      // Иницициализация покупки сервис
      const buyServicesSelected = useCookie("selectedServiceId");
      let _buyServicesSelected = buyServicesSelected.value;
      if (_buyServicesSelected === undefined || _buyServicesSelected === '' || _buyServicesSelected === null) {
        _buyServicesSelected = useAppConfigStore().$state.vars?.['Сервис выбора по умолчанию'];
      }
      buyServicesSelected.value = _buyServicesSelected;
      await this.changeBuyServicesSelectedNotApi(String(_buyServicesSelected));
    },

    actionBuyNumber: async function (params: IActionBuyNumber) {

      const router = useRouter();
      const nuxtApp = useNuxtApp();
      const smsUser = useSmsUser();
      const sitisAuth = useSitisAuth();
      const localePath = useLocalePath();
      const selectCountry: any = this.buyCountries.find((t: any) => Boolean(String(t.country_external_id) === String(params.countryId)));

      // Если нет пользователя, переходим на страницу авторизации
      if (!sitisAuth?.currentUser?.value?.id) {
        const saveLastParamsBuyNumber = useCookie("save-last-params-buy-number");
        saveLastParamsBuyNumber.value = JSON.stringify({
          serviceId: params.serviceId,
          countryId: params.countryId,
        });
        router.push(localePath('/authorization'));
        return
      }

      this.changeLoadingCards(params, true);

      // Обновляем и проверяем баланс пользователя
      await smsUser.getBalance();
      if (Number.parseFloat(selectCountry?.price || '0') > smsUser.balance) {
        const saveLastParamsBuyNumber = useCookie("save-last-params-buy-number");
        saveLastParamsBuyNumber.value = JSON.stringify({
          serviceId: params.serviceId,
          countryId: params.countryId,
        });
        this.changeLoadingCards(params, false);
        router.push(localePath('/payments'));
        return
      }

      // Метод покупки номера
      const _resBuy = await nuxtApp.$api.agent.get(`/sms-users/get-number/${ params.countryId }/${ params.serviceId }`).then((res: any) => {
        return res._data
      }).catch((err: any) => {
        return {error: err.response?._data}
      });
      if (_resBuy.error) {
        this.changeLoadingCards(params, false);
        nuxtApp.$toast.error(_resBuy.error?.message || "")
        return
      }

      nuxtApp.$toast.success("Номер успешно приобретен. Скоро он появится у вас в \"Полученные номера\"");
      router.push(localePath('/profile'))
    },
    changeLoadingCards: function (params: IActionBuyNumber, value: boolean) {
      const paramsValue: string = [params.countryId, params.serviceId].join("__");

      let _loadingCards: any = [...this.loadingCards];
      let _includes = Boolean(_loadingCards.find((t: string) => Boolean(t === paramsValue)));

      if (value && !_includes) {
        _loadingCards.push(paramsValue);
      }
      if (!value && _includes) {
        _loadingCards.splice(_loadingCards.findIndex((t: string) => Boolean(t === paramsValue), 1));
      }

      this.loadingCards = _loadingCards;
    },
  },
});
