<template>
  <div>
    <NuxtLayout>
      <main class="main">

      </main>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';

const props = defineProps({
  error: {
    type: Object as any
  }
});

const cmsStore = cmsContentStore();
const storeCatalog = catalogStore();
const appConfigStore = useAppConfigStore();

useHead(() => {
  const head: any = {
    title: '',
    meta: [],
    link: []
  };
  const favicon = appConfigStore?.$state?.config?.favicon || null;
  if (favicon) {
    head.link.push({ rel: 'icon', type: 'image/x-icon', href: favicon });
  }

  return head;
});
// onServerPrefetch(async () => {
//   await cmsStore.getMenu();
//   await appConfigStore.getConfig();
//   await storeCatalog.getCatalog();
// });
</script>

<style lang="scss" scoped>

</style>
