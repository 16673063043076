import { defineStore } from 'pinia';

interface IConfig {
  message?: string;
}

let timeoutUpdateBalance: any = null;
export const useSmsUser = defineStore({
  id: "sms-user",

  state: () => {
    return {
      balance: 0
    }
  },

  actions: {
    getBalance: async function () {
      clearTimeout(timeoutUpdateBalance);

      const { $api } = useNuxtApp();
      const _res = await $api.agent.get('/sms-users/balance').then((res: any) => {
        return res?._data || 0
      }).catch(() => {
        return 0
      });
      this.balance = _res;

      timeoutUpdateBalance = setTimeout(async () => {
        await this.getBalance();
      }, (30 * 1000));
    }
  },
});
