import { defineNuxtPlugin } from '#app';

const unlocalePath = defineNuxtPlugin((nuxtApp: any) => {
  nuxtApp.provide('unlocalePath', (path: string) => {
    for (const locale of useI18n().availableLocales) {
      const prefix = "/" + locale;
      if (path.startsWith(prefix)) {
        return path.slice(prefix.length);
      }
    }
    return path;
  });
});

export default unlocalePath
