import { default as _91_46_46_46cms_93mxLKLdEohXMeta } from "/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/[...cms].vue?macro=true";
import { default as indexJcB6NqNOsZMeta } from "/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/authorization/index.vue?macro=true";
import { default as _91_46_46_46slug_93ogLaG1pkQyMeta } from "/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/blog/[...slug].vue?macro=true";
import { default as index9BaHYpydWcMeta } from "/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/blog/index.vue?macro=true";
import { default as indexHZbieGXmy5Meta } from "/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/confirm/index.vue?macro=true";
import { default as indexb14YZksk2vMeta } from "/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/profile/index.vue?macro=true";
import { default as indexfEPGJODaxrMeta } from "/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/registration/index.vue?macro=true";
import { default as indexmLsENG7f6VMeta } from "/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/request-password/index.vue?macro=true";
export default [
  {
    name: "cms___en___default",
    path: "/:cms(.*)*",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/[...cms].vue")
  },
  {
    name: "cms___en",
    path: "/en/:cms(.*)*",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/[...cms].vue")
  },
  {
    name: "cms___ru",
    path: "/ru/:cms(.*)*",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/[...cms].vue")
  },
  {
    name: "authorization___en___default",
    path: "/authorization",
    meta: indexJcB6NqNOsZMeta || {},
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/authorization/index.vue")
  },
  {
    name: "authorization___en",
    path: "/en/authorization",
    meta: indexJcB6NqNOsZMeta || {},
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/authorization/index.vue")
  },
  {
    name: "authorization___ru",
    path: "/ru/authorization",
    meta: indexJcB6NqNOsZMeta || {},
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/authorization/index.vue")
  },
  {
    name: "blog-slug___en___default",
    path: "/blog/:slug(.*)*",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug(.*)*",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___ru",
    path: "/ru/blog/:slug(.*)*",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/blog/[...slug].vue")
  },
  {
    name: "blog___en___default",
    path: "/blog",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/blog/index.vue")
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/blog/index.vue")
  },
  {
    name: "confirm___en___default",
    path: "/confirm",
    meta: indexHZbieGXmy5Meta || {},
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/confirm/index.vue")
  },
  {
    name: "confirm___en",
    path: "/en/confirm",
    meta: indexHZbieGXmy5Meta || {},
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/confirm/index.vue")
  },
  {
    name: "confirm___ru",
    path: "/ru/confirm",
    meta: indexHZbieGXmy5Meta || {},
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/confirm/index.vue")
  },
  {
    name: "profile___en___default",
    path: "/profile",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/profile/index.vue")
  },
  {
    name: "profile___en",
    path: "/en/profile",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/profile/index.vue")
  },
  {
    name: "profile___ru",
    path: "/ru/profile",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/profile/index.vue")
  },
  {
    name: "registration___en___default",
    path: "/registration",
    meta: indexfEPGJODaxrMeta || {},
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/registration/index.vue")
  },
  {
    name: "registration___en",
    path: "/en/registration",
    meta: indexfEPGJODaxrMeta || {},
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/registration/index.vue")
  },
  {
    name: "registration___ru",
    path: "/ru/registration",
    meta: indexfEPGJODaxrMeta || {},
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/registration/index.vue")
  },
  {
    name: "request-password___en___default",
    path: "/request-password",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/request-password/index.vue")
  },
  {
    name: "request-password___en",
    path: "/en/request-password",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/request-password/index.vue")
  },
  {
    name: "request-password___ru",
    path: "/ru/request-password",
    component: () => import("/var/www/grizzly-frontend-mirrors/release-20241011094925/pages/request-password/index.vue")
  }
]