<template>
  <section>
    <NuxtLayout>
      <main class="main">
        <NuxtPage />
      </main>
    </NuxtLayout>

    <DialogLoading v-if="loadingStore._isLoading" />
  </section>
</template>

<script lang="ts" setup>
import { useUserAuth } from './composables/user/UseUserAuth';
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { useCurrencyStore } from '~/store/currency';
import { useLoadingStore } from '~/store/loading';
import { useBuyRentNumberStore } from '~/store/buyRentNumber';

const cmsStore = cmsContentStore();
const storeCatalog = catalogStore();
const appConfigStore = useAppConfigStore();
const сurrencyStore = useCurrencyStore();
const loadingStore = useLoadingStore();
const buyRentNumber = useBuyRentNumberStore();
const sitisAuth = useSitisAuth();

const DialogLoading = defineAsyncComponent(() => import('~/components/UI/DialogLoading.vue'));

useHead(() => {
  const head: any = {
    title: '',
    meta: [],
    link: []
  };
  const favicon = appConfigStore?.$state?.config?.favicon || null;

  if (favicon) {
    head.link.push({ rel: 'icon', type: 'image/x-icon', href: favicon });
  }

  return head;
});
onServerPrefetch(async () => {
  await cmsStore.getMenu();
  await appConfigStore.getConfig();
  await storeCatalog.getCatalog();
  await buyRentNumber.getBuyServices();
});

const footerCounter: any = computed(() => {
  return appConfigStore?.$state?.config?.counters?.footer || '';
});
const linkCanonical: ComputedRef<string> = computed(() => {
  const event = useRequestEvent();
  const host = process.server ? event?.node.req.headers.host || '' : window.location.host;
  const [path] = useRoute().fullPath.split('?');
  return `https://${host}${path}`;
});

onMounted(async () => {
  await buyRentNumber.initAllState();
  await buyRentNumber.getBuyCountries();

  // Инициализация пользователя
  const authToken = useCookie('authToken');
  if (authToken.value) {
    await sitisAuth.getUser(true);
  }

  window.addEventListener('vite:preloadError', (event) => {
    window.location.reload();
  });

  // Получение текущего курса USD
  await сurrencyStore.getCurrencyUsd();
});
</script>

<style lang="scss">

</style>
