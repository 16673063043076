import routerOptions0 from "/var/www/grizzly-frontend-mirrors/release-20241011094925/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/grizzly-frontend-mirrors/release-20241011094925/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}