import { defineStore } from 'pinia';

interface IConfig {
  message?: string;
}
export const useLoadingStore = defineStore({
  id: "loading",

  state: () => {
    return {
      _isLoading: false,
    }
  },

  actions: {
    changeLoading: function (val: boolean, config?: IConfig) {
      this._isLoading = val;
    }
  },
});
